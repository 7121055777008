import {
    ChangeDetectionStrategy,
    Component,
    computed,
    inject,
    Input,
    OnDestroy,
    OnInit,
    signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { AuthService } from '@interacta-shared/data-access-auth';
import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import { Theme } from '@interacta-shared/data-access-theme';
import { filterMap } from '@interacta-shared/util';
import * as AdminV2SharedActions from '@modules/admin-v2-shared/store/shared.actions';
import { AIActions } from '@modules/ai/store/ai.actions';
import { dashboardCommunity, postCreate } from '@modules/app-routing/routes';
import { ICommunity } from '@modules/communities/models/communities.model';
import { CommunityActions } from '@modules/communities/store';
import {
    selectAdminV2TabOpened,
    selectAdvancedCommunityResearchOpened,
    selectRecentCommunities,
} from '@modules/communities/store/community/community.selectors';
import { UserCapabilities } from '@modules/core';
import { UserCapabilitiesService } from '@modules/core/services/user-capabilites.service';
import { AppSelectors, AppState } from '@modules/core/store';
import {
    CreateButtonDisabledAdminSections,
    CreateButtonDisabledSections,
    CreateButtonSections,
    SectionSelected,
    SidebarSection,
    SidebarSectionId,
    SidebarWorkspace,
} from '@modules/sidebar/models/sidebar.model';
import {
    AdminV2Section,
    CommunityAdvancedSection,
    createNewButtonLabel,
    filterHiddenSections,
    filterWorkspaces,
    getSelectedSidebarSection,
    routeToSidebarSectionAdmin,
    sidebarNotScrollableSections,
    sidebarScrollableSectionItems,
} from '@modules/sidebar/models/sidebar.utils';
import { SidebarService } from '@modules/sidebar/services/sidebar.service';
import { selectShowDashboardLink } from '@modules/sidebar/store/sidebar.selectors';
import { CommunitiesStateService } from '@modules/state/services/communities-state.service';
import { StateService } from '@modules/state/services/state.service';
import { UIStateService } from '@modules/state/services/ui-state.service';
import { WfDesignerActions } from '@modules/workflow-designer/store';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import {
    distinctUntilChanged,
    filter,
    map,
    shareReplay,
    switchMap,
    takeUntil,
} from 'rxjs/operators';
import { SidebarSectionAdminId } from './../../models/sidebar.model';

const COMMUNITY_NUMBER_LIMIT = 10;

@Component({
    selector: 'interacta-sidebar',
    templateUrl: './sidebar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit, OnDestroy {
    @Input({ required: true }) currentTheme!: Theme;
    @Input() expanded = false;
    @Input() enableChristmasEasterEgg = false;

    private readonly workspacesUiService = inject(UIStateService);
    private readonly router = inject(Router);
    private readonly communitiesStateService = inject(CommunitiesStateService);
    private readonly configurationService = inject(ConfigurationService);
    private readonly store = inject(Store<AppState>);
    private readonly userCapabilitiesService = inject(UserCapabilitiesService);
    private readonly authService = inject(AuthService);
    private readonly sidebarService = inject(SidebarService);
    private readonly appState = inject(StateService);
    private destroy$ = new Subject<void>();

    showDashboardLink$ = this.store.select(selectShowDashboardLink);
    operativityCommunities = toSignal(
        this.appState.communitiesState.operativityCommunities$,
    );
    showCommunitySelectionDialog =
        this.sidebarService.communitySelectionForCreateDialogSettings;
    recentCommunities = this.store.selectSignal(selectRecentCommunities);
    selectedCommunity = toSignal(
        this.communitiesStateService.getCurrentCommunity(),
        {
            initialValue: null,
        },
    );

    /**
     * advancedCommunitySidebar = true means that communities are displayed in submenu
     * advancedCommunitySidebar = false means that communities are displayed under
     *      community menu item and there isn't a community submenu
     */
    advancedCommunitySidebar$!: Observable<boolean>;
    showAdvancedCommunityResearch = this.store.selectSignal(
        selectAdvancedCommunityResearchOpened,
    );
    showAdminV2Sidebar = this.store.selectSignal(selectAdminV2TabOpened);
    isAnimatingAdvancedTab = signal(false);
    isAnimatingAdminV2Tab = signal(false);
    sectionSelected$ = combineLatest([
        this.store.select(AppSelectors.selectRouteState),
        this.showDashboardLink$,
    ]).pipe(
        filterMap(([route, showDashboardLink]) =>
            route != null ? { route, showDashboardLink } : undefined,
        ),
        map(({ route, showDashboardLink }) => ({
            sectionId: getSelectedSidebarSection(route, showDashboardLink),
            sectionAdminId: routeToSidebarSectionAdmin(route),
            route,
        })),
        shareReplay(),
        takeUntilDestroyed(),
    );

    sectionSelected = toSignal(this.sectionSelected$);

    createNewDisabled = computed(() =>
        this.disableCreateNewButton(
            this.sectionSelected(),
            this.operativityCommunities()?.length ?? 0,
            this.selectedCommunity(),
            this.userCapabilities(),
        ),
    );

    sidebarWorkspaces$!: Observable<SidebarWorkspace[]>;
    operativityWorkspaces$!: Observable<SidebarWorkspace[]>;
    workspacesUI$ = this.appState.uiState.workspacesUI.onDataChange();
    userCapabilities = this.userCapabilitiesService.userCapabilities;

    readonly communityAdvancedSection = CommunityAdvancedSection;
    readonly adminV2Section = AdminV2Section;
    readonly sidebarNotScrollableSections = sidebarNotScrollableSections;
    readonly createNewButtonLabel = createNewButtonLabel;
    readonly createButtonSections = CreateButtonSections;

    private currentUser = this.authService.currentUserData;

    private insightCommunities = toSignal(
        this.appState.communitiesState.insightsCommunities$,
    );

    otherSidebarSections = computed<SidebarSection[]>(() => {
        const insightCommunities = this.insightCommunities() ?? [];
        const userCapabilities = this.userCapabilities();

        return sidebarScrollableSectionItems.map((section) => {
            switch (section.id) {
                case 'insights':
                    return {
                        ...section,
                        hidden: insightCommunities.length === 0,
                    };
                case 'admin-v2':
                    return {
                        ...section,
                        hidden: !userCapabilities?.accessAdminArea,
                    };
                case 'people':
                    return {
                        ...section,
                        hidden: !this.currentUser()?.userSettings
                            ?.peopleSectionEnabled,
                    };
                case 'digital_workplace_admin':
                    return {
                        ...section,
                        hidden: !userCapabilities?.manageDigitalWorkplace,
                    };
                default:
                    return section;
            }
        });
    });

    otherVisibleSidebarSections = computed(() =>
        filterHiddenSections(this.otherSidebarSections()),
    );

    aiEnabled = computed(
        () =>
            this.configurationService.environmentInfo()?.installedFeatures
                .aiGuideCenter ?? false,
    );

    ngOnInit(): void {
        const communityTree$ = this.appState.communitiesState.communityTree$;

        const workspaces$ = communityTree$.pipe(
            map((communityTree) => communityTree?.workspaceList ?? []),
            shareReplay(1),
        );

        this.operativityWorkspaces$ = workspaces$.pipe(
            map((workspaces) =>
                workspaces
                    .map((workspace) =>
                        filterWorkspaces(workspace, '', true, true),
                    )
                    .filter((workspace) => workspace.communities?.length),
            ),
        );

        this.sidebarWorkspaces$ = workspaces$.pipe(
            map((workspaces) =>
                workspaces.filter(
                    (workspace) => workspace.visibleInOrganizationTree,
                ),
            ),
        );

        this.advancedCommunitySidebar$ = communityTree$.pipe(
            map(
                (communityTree) =>
                    communityTree?.communityList.filter(
                        (c) => c.visibleInOrganizationTree,
                    ) ?? [],
            ),
            map((communities) => communities.length > COMMUNITY_NUMBER_LIMIT),
            distinctUntilChanged(),
        );

        this.sectionSelected$
            .pipe(
                filter((section) =>
                    this.computeFilterForToggleSecondarySidebar(
                        this.showAdminV2Sidebar(),
                        section.sectionId,
                        'admin-v2',
                    ),
                ),
            )
            .subscribe(() => this.toggleAdminSidebar());

        this.sectionSelected$
            .pipe(
                switchMap((section) =>
                    this.advancedCommunitySidebar$.pipe(
                        map((canShow) => ({
                            section,
                            isShowing: this.showAdvancedCommunityResearch(),
                            canShow,
                        })),
                    ),
                ),
                filter(({ section, isShowing, canShow }) =>
                    this.computeFilterForToggleSecondarySidebar(
                        isShowing,
                        section.sectionId,
                        'community',
                        canShow,
                    ),
                ),
            )
            .subscribe(() => this.toggleAdvancedCommunityResearch());
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    onCollapsedWs(id: number): void {
        this.workspacesUiService.toggleWorkspaceOpen(id);
    }

    toggleAdvancedSidebar(
        sectionId: SidebarSectionId,
        showSpecificSidebar: boolean,
    ): void {
        if (sectionId === 'community') {
            this.toggleAdvancedCommunityResearchAndSwitchFocus(
                showSpecificSidebar,
            );
        } else if (sectionId === 'admin-v2') {
            this.toggleAdvancedAdminSidebarAndSwitchFocus(showSpecificSidebar);
        }
    }

    toggleAdvancedCommunityResearchAndSwitchFocus(
        showAdvancedCommunityResearch: boolean,
    ): void {
        this.toggleAdvancedCommunityResearch();
        requestAnimationFrame(() => {
            document
                .getElementById(
                    showAdvancedCommunityResearch
                        ? 'community'
                        : 'advancedCommunityResearch',
                )
                ?.focus();
        });
    }

    toggleAdvancedAdminSidebarAndSwitchFocus(showAdminSidebar: boolean): void {
        this.toggleAdminSidebar();
        requestAnimationFrame(() => {
            document
                .getElementById(
                    showAdminSidebar ? 'admin-v2' : 'adminV2Sidebar',
                )
                ?.focus();
        });
    }

    handleCreateClick(
        sectionSelected: SectionSelected,
        selectedCommunity: ICommunity | null,
    ): void {
        if (sectionSelected.sectionId === 'admin-v2') {
            if (sectionSelected.sectionAdminId === 'workflow-template') {
                this.store.dispatch(WfDesignerActions.createWorkflow({}));
            } else {
                this.store.dispatch(
                    AdminV2SharedActions.openCreationDialog({ isOpen: true }),
                );
            }
        } else {
            if (selectedCommunity) {
                this.router.navigate([postCreate, selectedCommunity.id]);
            } else {
                this.sidebarService
                    .openCommunitySelectionForCreateDialog()
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((selected) => {
                        this.router.navigate([postCreate, selected.id]);
                    });
            }
        }
    }

    navigateToCommunity(community: Pick<ICommunity, 'id'>): void {
        this.router.navigate(['/', dashboardCommunity, community.id]);
    }

    disableCreateNewButton(
        sectionSelected: SectionSelected | undefined,
        operativityCommunitiesLength: number,
        selectedCommunity: ICommunity | null,
        userCapabilities: UserCapabilities | null,
    ): boolean {
        if (!sectionSelected) {
            return true;
        }

        if (CreateButtonDisabledSections.includes(sectionSelected.sectionId)) {
            return true;
        }

        if (
            sectionSelected.sectionId === 'admin-v2' &&
            sectionSelected.sectionAdminId !== null
        ) {
            if (
                CreateButtonDisabledAdminSections.includes(
                    sectionSelected.sectionAdminId,
                )
            ) {
                return true;
            }
            if (
                this.cantCreateInAdmin(
                    sectionSelected.sectionAdminId,
                    userCapabilities,
                )
            ) {
                return true;
            }
        }

        return (
            this.isHomeManageDisabled(
                sectionSelected,
                selectedCommunity,
                operativityCommunitiesLength,
            ) ||
            this.canCreatePostInCommunity(sectionSelected, selectedCommunity)
        );
    }

    private isHomeManageDisabled(
        sectionSelected: SectionSelected,
        selectedCommunity: ICommunity | null,
        operativityCommunitiesLength: number,
    ): boolean {
        return (
            (['home', 'manage'].includes(sectionSelected.sectionId) ||
                (sectionSelected.sectionId === 'community' &&
                    !selectedCommunity)) &&
            !operativityCommunitiesLength
        );
    }

    private canCreatePostInCommunity(
        sectionSelected: SectionSelected,
        selectedCommunity: ICommunity | null,
    ): boolean {
        return (
            sectionSelected.sectionId === 'community' &&
            selectedCommunity != null &&
            !selectedCommunity.capabilities?.createPost
        );
    }

    private cantCreateInAdmin(
        sectionAdminId: SidebarSectionAdminId,
        userCapabilities: UserCapabilities | null,
    ): boolean {
        let cantCreate = false;
        switch (sectionAdminId) {
            case 'user':
            case 'group':
                cantCreate = !userCapabilities?.createUsers;
                break;
            case 'workspace':
                cantCreate = !userCapabilities?.createWorkspaces;
                break;
            case 'community':
                cantCreate = !(
                    userCapabilities?.createCommunities ||
                    userCapabilities?.createProjectCommunities
                );
                break;
            case 'workflow-template':
                cantCreate = !userCapabilities?.manageWorkflowTemplates;
                break;
            case 'catalog':
                cantCreate = !userCapabilities?.manageCatalogs;
                break;
            // Add other cases as needed
        }
        return cantCreate;
    }

    openAIPanel(): void {
        this.store.dispatch(AIActions.openGuide());
    }

    private toggleAdvancedCommunityResearch(): void {
        this.store.dispatch(CommunityActions.toggleAdvancedCommunityResearch());
        this.isAnimatingAdvancedTab.set(true);
    }

    private toggleAdminSidebar(): void {
        this.store.dispatch(CommunityActions.toggleAdminV2Tab());
        this.isAnimatingAdminV2Tab.set(true);
    }

    private computeFilterForToggleSecondarySidebar(
        isShowing: boolean,
        sectionId: SidebarSectionId,
        sectionToCheck: SidebarSectionId,
        canShow = true,
    ): boolean {
        return (
            ((isShowing && sectionId !== sectionToCheck) ||
                (!isShowing && sectionId === sectionToCheck)) &&
            canShow
        );
    }
}
